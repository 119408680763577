<template>
  <div>
    <!-- 提示信息 -->
    <div class="map-hint">
      双击目标位置设置电子围栏
    </div>

    <!-- 地图容器 -->
    <div class="bmap" id="bmap" ref="bmap"></div>
  </div>
</template>

<script>
import 'echarts/extension/bmap/bmap';
import axios from 'axios';

export default {
  name: 'BmapSinglePoint',
  data() {
    return {
      center_latitude: null, // 电子围栏中心的纬度
      center_longitude: null, // 电子围栏中心的经度
      center_rad: null, // 电子围栏的半径
      deviceid: null // 示例设备 ID，可以根据需要动态获取
    };
  },
  mounted() {
    this.deviceid = this.$route.query.deviceid
    const latitude = parseFloat(this.$route.query.latitude) || 31.2317; // 默认值为上海中心
    const longitude = parseFloat(this.$route.query.longitude) || 121.4726; // 默认值为上海中心
    const address = this.$route.query.address || '未知地点'; // 默认值为 '未知地点'

    this.initBmap(longitude, latitude, address);
  },
  methods: {
    initBmap(longitude, latitude, address) {
      const myChart = this.$echarts.init(this.$refs.bmap);

      const mapOption = {
        bmap: {
          center: [longitude, latitude], // 设置地图中心为传入的坐标
          zoom: 15, // 地图缩放级别
          roam: true // 允许用户拖动和缩放地图
        }
      };

      myChart.setOption(mapOption);

      // 获取 BMap 实例
      const map = myChart.getModel().getComponent('bmap').getBMap();

      // 设置淡色地图样式
      map.setMapStyle({
        styleJson: [
          {
            featureType: 'land',
            elementType: 'geometry',
            stylers: {color: '#f7f7f7'} // 调整为浅灰色
          },
          {
            featureType: 'building',
            elementType: 'geometry',
            stylers: {color: '#e0e0e0'} // 建筑物颜色为浅灰色
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: {color: '#a4d1f2'} // 水体颜色为淡蓝色
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: {color: '#ffffff'} // 道路颜色为白色
          },
          {
            featureType: 'green',
            elementType: 'geometry',
            stylers: {color: '#d6f5d6'} // 绿地颜色为浅绿色
          },
          {
            featureType: 'boundary',
            elementType: 'all',
            stylers: {color: '#cccccc'} // 边界颜色为浅灰色
          }
        ]
      });

      // 在初始位置添加一个标记点（水滴标记）
      this.addMarker(map, {lng: longitude, lat: latitude}, null, address);

      // 监听地图双击事件，获取点击位置的经纬度
      map.addEventListener('dblclick', (e) => {
        const point = e.point;
        const longitude = point.lng.toFixed(4);
        const latitude = point.lat.toFixed(4);

        // 弹出窗口，要求用户输入电子围栏半径
        let radius = parseFloat(prompt('请输入电子围栏半径（单位：米，范围：250 - 2000）'));
        while (isNaN(radius) || radius < 250 || radius > 2000) {
          alert('请输入有效的半径（范围：250 - 2000米）');
          radius = parseFloat(prompt('请输入电子围栏半径（单位：米，范围：250 - 2000）'));
        }

        // 保存电子围栏的中心坐标和半径
        this.center_latitude = latitude;
        this.center_longitude = longitude;
        this.center_rad = radius;

        console.log("看看围栏")
        console.log(latitude, longitude, radius)
        console.log("看看设备")
        console.log(this.deviceid)

        // 在地图上显示点击的点，并显示电子围栏
        this.addMarker(map, point, radius);

        // 上传电子围栏设置
        this.uploadElectricFence(this.deviceid, longitude, latitude, radius);
      });
    },
    addMarker(map, point, radius, name = '新位置') {
      // 添加标记点（水滴标记）
      const marker = new BMap.Marker(point);
      map.addOverlay(marker);

      // 如果设置了电子围栏半径，添加电子围栏（圆形区域）
      if (radius) {
        const circle = new BMap.Circle(point, radius, {
          strokeColor: 'blue',
          fillColor: 'rgba(0, 0, 255, 0.2)',
          strokeWeight: 2,
          fillOpacity: 0.3,
        });
        map.addOverlay(circle);
      }

      // 标记点的标签
      const label = new BMap.Label(name, {position: point, offset: new BMap.Size(20, -10)});
      map.addOverlay(label);

      // 将视野调整到标记点的位置
      map.panTo(point);
    },
    async uploadElectricFence(deviceid, longitude, latitude, radius) {
      try {
        const url = `https://yg.hxinwatch.com/sdkapi/api/push/hjk/updateelectricfence.htm`;
        const response = await axios.get(url, {
          params: {
            deviceid: deviceid,
            longitude: longitude,
            latitude: latitude,
            fanwei: radius,
          }
        });

        if (response.data.code === 200) {
          alert('电子围栏设置成功');
        } else {
          alert(`电子围栏设置失败: ${response.data.msg}`);
        }
      } catch (error) {
        alert('电子围栏设置失败，请检查网络连接或重试');
      }
    }
  }
};
</script>

<style scoped>
.bmap {
  width: 100%;
  height: 90vh; /* 保留 10vh 作为提示信息的显示空间 */
}

/* 提示信息的样式 */
.map-hint {
  text-align: center;
  padding: 10px;
  background-color: #f0f8ff; /* 提示背景色为浅蓝色 */
  font-size: 16px;
  color: #333; /* 文字颜色 */
  border-bottom: 1px solid #ddd; /* 边框 */
}
</style>
